import React from 'react';
import Loadable from 'react-loadable';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import radioPreviewData from '../../../../data/previews/radio.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Radio = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Radio;
    return <Component {...props} />;
  },
});

const RadioGroup = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.RadioGroup;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      radioValue: '',
      radioSizeValue: 'medium',
    };
  }

  onRadioChange = value => this.setState({ radioValue: value });
  onRadioSizeChange = value => this.setState({ radioSizeValue: value });

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Radio"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Radio" />
        <V5Notice
          componentName="Radio"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-radios--docs"
        />

        <PageNavigation
          links={[
            'Size',
            'State',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Size">
          <Paragraph>A radio can be any of the four available sizes.</Paragraph>
          <ComponentPreview
            name="RadioSize"
            previewData={radioPreviewData.sizes}>
            <RadioGroup
              header="Recipients"
              onChange={this.onRadioSizeChange.bind(this)}
              valueChecked={this.state.radioSizeValue}>
              <Radio label="Coaches" value="coaches" />
              <Radio label="Athletes" value="athletes" />
              <Radio label="Opposing Team" value="opposingTeam" isDisabled />
            </RadioGroup>
          </ComponentPreview>
          <Paragraph>
            A form’s density (standard, compact) is dictated by the form
            modifier component. Check{' '}
            <Link href="/components/forms/form-modifier/design">
              {' '}
              those guidelines
            </Link>{' '}
            for more detailed recommendations.
          </Paragraph>
        </Section>

        <Section title="State">
          <Paragraph>
            A radio’s state depends on the interaction itself or a submission
            error.
          </Paragraph>
          <ComponentPreview name="RadioState">
            <RadioGroup
              header="Recipients"
              name="testRadio"
              onChange={this.onRadioChange.bind(this)}
              valueChecked={this.state.radioValue}>
              <Radio label="Coaches" value="coaches" name="testRadio" />
              <Radio label="Athletes" value="athletes" name="testRadio" />
              <Radio
                label="Opposing Team"
                value="opposingTeam"
                name="testRadio"
                isDisabled
              />
            </RadioGroup>
          </ComponentPreview>
          <Paragraph>
            The actual <em>focus</em> state is sorted for you, so anyone
            navigating via keyboard or screen reader is set. A disabled radio
            prevents the user from interacting with a field until certain
            conditions are met. It’s a good way to prevent invalid submissions,
            but should be used sparingly.
          </Paragraph>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Uniform radio buttons may be used for both Apple and Android
            devices. Keep a close eye on the intent of the radio and never use
            it outside of a form.
          </Paragraph>

          <Paragraph>
            If the requirement is to switch between different views, use a{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/segmented-controls/">
              segmented control
            </Link>{' '}
            instead. When asking the user to enable or disable a function,
            feature or setting, use a{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/">
              switch
            </Link>
            .
          </Paragraph>
        </Section>
        <SectionUsage>
          <SectionSubhead>Radio Buttons</SectionSubhead>
          <Paragraph>
            The “button” or “button-minimal” display type can be used to show
            the radio options as a row or column of buttons. These should be
            used sparingly as a way for users to quickly select option(s) and
            only when all of the options are concise - no more than 15
            characters.
          </Paragraph>
          <DontDo
            dontText="include long labels in the radio button options."
            doText="use radio buttons to toggle or filter data."
            imgFilename="radio-button"
          />
        </SectionUsage>

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            A label should clearly and concisely describe the field’s purpose
            with no more than a few words.
          </Paragraph>
          <DontDo
            dontText="make the label a complete sentence."
            doText="keep it short and sweet."
            imgFilename="radio-labels"
          />
          <Paragraph>
            Check{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product/">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>

          <SectionMicroHelpText imgNameError="radio-errorhelptext" />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Forms / Radio" />

        <SectionPlatform />
      </PageWithSubNav>
    );
  }
}
